import {
  BASEURL,
  checktoken,
  convertHTMLToPlainText,
  headers,
  languages,
} from "../../../utils/helper";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TranslateIcon from "@mui/icons-material/Translate";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Toaster from "../../../components/common/Toaster";
import { Button, IconButton } from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-dropdown-select";
import { Fragment, useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import ProjectComponent from "./ProjectComponent";
import { SaveTranslationReq } from "../../../redux/slices/SaveTranslationSlice";
import { SaveTranslationHistory } from "../../../redux/slices/TranslationProjectSlice";
import plusIcon from "../../../assets/images/plus.svg";
import crossIcon from "../../../assets/images/cross-mark.svg";
import { marked } from "marked";
import { SaveTranscribeImageHistory } from "../../../redux/slices/TranscribeImageSlice";
import { SaveTranscribeImageReq } from "../../../redux/slices/SaveTranscribeImageSlice";

const FormatTextToHtml = ({ input }) => {
  let formattedText = input.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
  formattedText = formattedText.replace(/\n/g, "<br>");
  return <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
};

const TranscribeImage = () => {
  const [chat, setChat] = useState([]);
  const [limitError, setLimitError] = useState(false);
  const dispatch = useDispatch();

  const [spinner, setSpinner] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isTranscribe, setIsTranscribe] = useState(false);
  const [controller, setController] = useState(new AbortController());
  const user = useSelector((state) => state.AuthSlice);
  const [selectedImage, setSelectedImage] = useState(null);
  const { data: project, loading: tIloading } = useSelector(
    (state) => state.TranscribeImageSlice
  );

  const [projId, setProjId] = useState(null);
  useEffect(() => {
    if (project?._id) setProjId(project?._id);
  }, []);

  useEffect(() => {
    if (project) {
      if (project._id && projId !== project._id) {
        setSelectedImage(null);
        setIsTranscribe(false);
        setIsComplete(false);
      }
    }
  }, [project]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleStopStream = (e) => {
    controller.abort();
    setController(new AbortController());
  };

  const handleCopy = (content) => {
    content
      ? toast.success("Content copied to clipboard!")
      : toast.error("Content not found !");
  };

  const handleExtractText = async (e) => {
    e.preventDefault();
    if (!selectedImage) {
      toast.error("Please select an image!");
      return false;
    }
    setSpinner(true);
    setChat([]);

    if (selectedImage) {
      const payload = {
        img: selectedImage,
      };

      try {
        const response = await fetch(`${BASEURL}/user/image/transcribeImage`, {
          method: "POST",
          signal: controller.signal,
          responseType: "stream",
          headers: headers(),
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          setSpinner(false);

          throw new Error("Network response was not ok");
        }

        const reader = response.body.getReader();
        setIsTranscribe(true);
        const decoder = new TextDecoder();
        let result = "";
        let done = false;

        while (!done) {
          const { value, done: streamDone } = await reader.read();
          done = streamDone;
          result += decoder.decode(value, { stream: !done });
          setChat([...chat, { airesponse: result }]);
        }
        setIsComplete(true);
        const savehistory = {
          prompt: selectedImage,
          promptType: "image",
          type: "transcribeImage",
          airesponse: result,
          project: project._id,
        };
        const transcribeHistReq = await dispatch(
          SaveTranscribeImageHistory(savehistory)
        );
        console.log("complete response.../", chat);

        const saveTranscription = {
          text: selectedImage,
          airesponse: result,
          history_id:
            transcribeHistReq.payload.data[
              transcribeHistReq.payload.data.length - 1
            ]._id,
        };
        dispatch(SaveTranscribeImageReq(saveTranscription));

        setSpinner(false);

        // console.log("Extracted Text:", result);
        // Handle the extracted text as needed
      } catch (error) {
        setSpinner(false);

        console.error("There was a problem with the fetch operation:", error);
      }
    }
  };

  const getHTML = (text) => {
    console.log(typeof text);
    return text
      .toString()
      .replace(/\n/g, "<br/>")
      .replace(/\n\n/g, "<br/><br/>")
      .replace(/\n\n\n/g, "<br/><br/><br/>")
      .replace("`", "")
      .replace("``", "")
      .replace("```", "");
  };

  return (
    <div className="h-100">
      {user?.currentplan && <ProjectComponent />}
      <Toaster />
      <Row className="h-50 m-0 pt-3">
        <div className="">
          <div className="btn-thm-1 d-flex align-items-center flex-wrap">
            <span>
              <TranslateIcon />
            </span>
            {isTranscribe ? (
              <Button
                type="submit"
                className="bg-primary-light hover:bg-primary-main text-white"
                onClick={(e) => {
                  setIsTranscribe(false);
                  setIsComplete(false);
                  setSelectedImage(null);
                  setChat([]);
                  handleStopStream(e);
                }}
              >
                reset
              </Button>
            ) : (
              <Button
                onClick={(e) => {
                  handleExtractText(e);
                }}
                type="submit"
                className="bg-primary-light hover:bg-primary-main text-white"
              >
                transcribe Image {spinner === true && <ButtonSpinner />}
              </Button>
            )}
          </div>
          {limitError && (
            <div className="mt-3">
              <p className="font-bold text-red-500">Daily limit exceeded</p>
            </div>
          )}
        </div>
        {selectedImage && (
          <Col sm={12} md={6}>
            <div className="relative h-full w-full">
              <img
                src={selectedImage}
                alt="Selected"
                className="h-full w-full object-contain"
              />
              <button
                onClick={() => {
                  setIsTranscribe(false);
                  setIsComplete(false);
                  setSelectedImage(null);
                  setChat([]);
                }}
                className="absolute top-2 right-2 bg-white p-1 rounded-full"
              >
                <img src={crossIcon} alt="Remove" className="h-8" />
              </button>
            </div>
          </Col>
        )}
        {!selectedImage && !isTranscribe && (
          <Col sm={12} md={12}>
            <div className="h-96 flex justify-center items-center">
              <div
                className={`border-2 border-gray-300 flex justify-center items-center h-48 mx-auto w-[40%]`}
              >
                <div className="">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                    id="imageUpload"
                  />
                  <label
                    htmlFor="imageUpload"
                    className="cursor-pointer flex flex-col gap-3 justify-center items-center"
                  >
                    <img src={plusIcon} className="h-20" alt="plus icon" />
                    <p className="text-lg font-medium">
                      Select an Image to Transcribe
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </Col>
        )}
        <Col sm={12} md={6}>
          {chat?.length > 0 && (
            <div
              className="border rounded p-3"
              style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}
            >
              {chat.map((item, index) => {
                console.log("CHAT.../ ", index, getHTML(item.airesponse));
                return (
                  <Fragment key={index}>
                    <FormatTextToHtml input={item.airesponse} />

                    <div
                    // dangerouslySetInnerHTML={{
                    //   __html: item.airesponse
                    //     .replace(/\n/g, "<br/>")
                    //     .replace(/\n\n/g, "<br/><br/>")
                    //     .replace(/\n\n\n/g, "<br/><br/><br/>")
                    //     .replace("`", "")
                    //     .replace("``", "")
                    //     .replace("```", "")
                    //     .replace(`"`, ""),
                    //   // .replace("/\n/g", "<br/>"),
                    // }}
                    >
                      {/* {marked(convertHTMLToPlainText(item.airesponse))} */}
                    </div>
                    {isComplete && (
                      <div className="d-flex align-items-center justify-content-end mt-1">
                        <CopyToClipboard
                          text={convertHTMLToPlainText(item.airesponse)}
                        >
                          <IconButton
                            className="d-flex align-items-center"
                            onClick={() => handleCopy(item.airesponse)}
                          >
                            <ContentCopyIcon fontSize="inherit" />
                          </IconButton>
                        </CopyToClipboard>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TranscribeImage;
