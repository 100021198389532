import {
  Fragment,
  useRef,
  useState,
  useEffect,
  useMemo,
  Suspense,
  lazy,
} from "react";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import { HRProjectChatReq } from "../../../redux/slices/HRUploadSlice";
import { WordCountRequest } from "../../../redux/slices/CounterSlice";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import { BASEURL, checktoken, headers } from "../../../utils/helper";
import CustomTooltip from "../../../components/common/CustomTooltip";
import { handleFileUpload } from "../../../redux/slices/FileSlice";
import { Alert, Grid, IconButton, Snackbar } from "@mui/material";
import ChatSkelton from "../../../components/common/ChatSkelton";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AttachmentIcon from "@mui/icons-material/AttachFile";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Toaster from "../../../components/common/Toaster";
import { useDispatch, useSelector } from "react-redux";
import ico from "../../../assets/images/favicon.png";
import SquareIcon from "@mui/icons-material/Square";
import ProjectComponent from "./ProjectComponent";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import FileDrawer from "./FileDrawer";
import DOMPurify from "dompurify";
import axios from "axios";
const MarkdownViewer = lazy(() =>
  import("../../../components/chat/MarkdownViewer")
);

const HRUpload = () => {
  const chatBlock = useRef();
  const dispatch = useDispatch();
  const [chat, setChat] = useState([]);
  const [input, setInput] = useState("");
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState(1);
  const [stop, setStop] = useState(false);
  const [height, setHeight] = useState("");
  const [spinner, setSpinner] = useState(false);
  const user = useSelector((state) => state.AuthSlice);
  const { loading } = useSelector((state) => state.FileSlice);
  const [controller, setController] = useState(new AbortController());
  const { data: prompts, loading: historyLoading } = useSelector(
    (state) => state.AppHistorySlice
  );
  const {
    data: project,
    chat: chatlist,
    cloading,
  } = useSelector((state) => state.HRUploadSlice);
  const { data: allProjects } = useSelector((state) => state.AppProjectSlice);

  const { currentplan } = useSelector((state) => state.AuthSlice);
  const [currentPlanPackages, setCurrentPlanPackages] = useState([]);
  const [currentPlansStatus, setCurrentPlansStatus] = useState([]);
  useEffect(() => {
    if (currentplan) {
      const plans = [];
      let statuses = {};
      currentplan?.map((pack) => {
        statuses = { ...statuses, [pack?.plankey]: pack?.status };
        plans.push(pack?.plankey);
      });
      setCurrentPlansStatus(statuses);
      setCurrentPlanPackages(plans);
    }
  }, [currentplan]);

  //   console.log("allProjects.../", allProjects);
  const handleStopStream = (e) => {
    setStop(false);
    controller.abort();
    setController(new AbortController());
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleCopy = (content) => {
    content
      ? toast.success("Content copied to clipboard!")
      : toast.error("Content not found !");
  };

  const scrollChat = () => {
    setTimeout(function () {
      const objDiv = chatBlock.current;
      if (objDiv !== null && objDiv !== undefined) {
        objDiv.scrollTo({
          top: objDiv.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    });
  };

  useEffect(() => {
    if (prompts.length > 0) setChat([...prompts]);
  }, [prompts]);

  const handleSend = async (e) => {
    e.preventDefault();
    if (loading || spinner || stop) {
      return;
    }
    dispatch(WordCountRequest());
    setInput("");
    setSpinner(true);
    setChat([...chat, { role: "user", prompt: input }]);
    try {
      scrollChat();
      const message = JSON.stringify({ que: input, project: project?._id });
      const response = await fetch(`${BASEURL}/user/hrupload/chats`, {
        method: "POST",
        signal: controller.signal,
        responseType: "stream",
        headers: headers(),
        body: message,
      });

      //eslint-disable-next-line
      if (!response.ok) {
        const errorResponse = await response.json();
        toast.error(errorResponse.error || "Something went wrong");
        setSpinner(false);
        return;
      }
      const readData = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      setSpinner(false);
      setStop(true);
      let aiRes = "";
      while (true) {
        scrollChat();
        const { done, value } = await readData.read();
        if (done) {
          break;
        }
        aiRes += value;
        setChat([
          ...chat,
          { role: "user", prompt: input },
          { airole: "assistant", airesponse: aiRes },
        ]);
        scrollChat();
      }
      setStop(false);
      if (aiRes.length > 10) {
        const saveprompt = {
          prompt: input,
          type: "hr",
          airesponse: aiRes,
          project: project._id,
        };
        setSpinner(false);
        saveHistory(saveprompt);
        dispatch(WordCountRequest());
      }
    } catch (err) {
      setSpinner(false);
      checktoken(err);
      if (err?.response?.data?.error) {
        toast.error(err.response.data.error);
      }
    }
  };

  const saveHistory = (data) => {
    axios
      .post(`${BASEURL}/user/create/chat/history`, data, { headers: headers() })
      .then((res) => {
        if (res.data.success === true) {
          setChat(res.data.data);
        }
        scrollChat();
      })
      .catch((err) => {
        checktoken(err);
        if (err?.response?.data?.error) {
          toast.error(err.response.data.error);
        }
      });
  };

  useEffect(() => {
    setHeight(document.getElementById("chat-layout").offsetHeight);
    if (
      user?.currentplan &&
      (user?.currentplan?.plankey === "execs_pro_monthly" ||
        user?.currentplan?.plankey === "execs_pro_semianual" ||
        user?.currentplan?.plankey === "execs_pro_yearly" ||
        user?.currentplan?.plankey === "team_member_monthly" ||
        user?.currentplan?.plankey === "team_member_semi" ||
        user?.currentplan?.plankey === "team_member_annual")
    ) {
      dispatch(WordCountRequest());
      if (project?._id) {
        dispatch(HRProjectChatReq({ id: project._id, type: "hr" }));
      }
    }
    scrollChat();
    //eslint-disable-next-line
  }, [project]);

  useMemo(() => {
    if (chatlist?.length) {
      setChat(chatlist);
    } else {
      setChat([]);
    }
    scrollChat();
    return chat;
  }, [project, chatlist]);

  useEffect(() => {
    setHeight(document.getElementById("chat-layout").offsetHeight);
    if (
      user?.currentplan &&
      (user?.currentplan?.plankey === "execs_pro_monthly" ||
        user?.currentplan?.plankey === "execs_pro_semianual" ||
        user?.currentplan?.plankey === "execs_pro_yearly" ||
        user?.currentplan?.plankey === "team_member_monthly" ||
        user?.currentplan?.plankey === "team_member_semi" ||
        user?.currentplan?.plankey === "team_member_annual")
    ) {
      // let projectObj = allProjects.find((item) => item.type === "hr");
      // console.log("projectObj.../", projectObj);

      dispatch(WordCountRequest());
      if (project?._id) {
        dispatch(HRProjectChatReq({ id: project._id, type: "hr" }));
      }
    }
    //eslint-disable-next-line
  }, []);

  // useMemo(() => {
  //     setSnack(true);
  //     return snack;
  // }, [loading])

  useEffect(() => {
    // console.log("input.length.../", input.length);
    // console.log("rows../", rows);
    if (input.length >= 0 && input.length < 120) setRows(1);
    else if (input.length >= 120 && input.length < 240) setRows(2);
    else if (input.length >= 240 && input.length < 360) setRows(3);
    else if (input.length >= 360 && input.length < 480) setRows(4);
    else if (input.length >= 480 && input.length < 600) setRows(5);
    else if (input.length > 600) setRows(6);
  }, [input]);

  return (
    <div
      className="flex flex-col"
      style={{ height: `calc(100vh - ${height}px)` }}
    >
      {user?.currentplan?.[0] &&
        (currentPlanPackages?.includes("pro_monthly") ||
          currentPlanPackages?.includes("pro_semianual") ||
          currentPlanPackages?.includes("hr_monthly") ||
          currentPlanPackages?.includes("hr_semianual")) && (
          <ProjectComponent />
        )}
      {loading && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          autoHideDuration={6000}
          key={{ vertical: "bottom", horizontal: "center" }}
          sx={{
            marginBottom: "40px", // This will move the Snackbar 70px upwards
          }}
        >
          <Alert severity="info" variant="filled" sx={{ width: "100%" }}>
            Files are uploading by the system and it may take few minutes,
            please be patient.
          </Alert>
        </Snackbar>
      )}
      <FileDrawer open={open} toggleDrawer={toggleDrawer} />
      <Toaster />
      <div
        id="chat-body"
        className="grow chat-block overflow-y-auto h-full p-[20px]"
        ref={chatBlock}
      >
        {cloading && <ChatSkelton />}
        {chat?.length > 0 && (
          <Grid
            container
            className={`hide-scroll-bar chat-section ${
              chat.length > 0 ? "flex justify-end flex-col" : ""
            }`}
          >
            {chat.map((item, index) => (
              <Fragment key={index}>
                {item.role === "user" && item.prompt !== "" && (
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start ${item.promptrole} w-100`}
                  >
                    <div className="d-flex ms-auto flex-grow-1 align-items-start">
                      <div className="d-block">
                        <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2">
                          {user?.firstname?.split("")[0]}
                          {user?.lastname?.split("")[0]}
                        </span>
                      </div>
                      <div
                        className="leading-loose w-100"
                        style={{
                          whiteSpace: "break-spaces",
                          wordBreak: "break-word",
                        }}
                      >
                        <div
                          className="question"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              item.prompt
                                .replaceAll("\\n", "")
                                .replaceAll("<br>", "")
                            ).replaceAll(/【.*?】/g, ""),
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                )}

                {item.airole === "assistant" && (
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start w-100 answer`}
                  >
                    <div className="d-block">
                      <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2 p-1">
                        <img className="w-100 h-100" src={ico} alt="ico" />
                      </span>
                    </div>
                    <div className="d-block flex-grow-1 answer-div">
                      <div
                        className="leading-loose"
                        style={{ fontWeight: 500 }}
                      >
                        <Suspense fallback={<div>Loading...</div>}>
                          <MarkdownViewer
                            className="answer-content"
                            content={DOMPurify.sanitize(
                              item.airesponse
                            ).replaceAll(/【.*?】/g, "")}
                          />
                        </Suspense>
                      </div>
                      {item?._id && (
                        <div className="d-flex align-items-center justify-content-end mt-1">
                          <CopyToClipboard text={item.airesponse}>
                            <IconButton
                              onClick={() =>
                                handleCopy(
                                  item.airesponse.replaceAll(/【.*?】/g, "")
                                )
                              }
                            >
                              <ContentCopyIcon fontSize="inherit" />
                            </IconButton>
                          </CopyToClipboard>
                        </div>
                      )}
                    </div>
                  </Grid>
                )}
              </Fragment>
            ))}
          </Grid>
        )}
      </div>
      <Grid
        container
        className="chat-actions sticky bottom-0 bg-white px-[20px] pb-[12px]"
        style={{ zIndex: "2" }}
      >
        <Grid
          item
          xs={12}
          md={10}
          className="chat-input-group chat-input-group-custom mx-auto"
        >
          <Form
            onKeyDown={(e) => {
              e.key === "Enter" && !e.shiftKey && e.preventDefault();
            }}
          >
            <Form.Group className="position-relative">
              <Form.Control
                placeholder="give criteria to search resumes and/or summarise. e.g. find top 3 with management skill..."
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                onKeyUp={(e) => {
                  e.key === "Enter" &&
                    !e.shiftKey &&
                    e.target.value.trim() !== "" &&
                    handleSend(e);
                }}
                value={input}
                as="textarea"
                rows={rows}
              />
              <div className="chat-btns">
                {input.length < 4 ? (
                  <Button type="button" disabled className="ochat-btn">
                    {spinner === true ? (
                      <ButtonSpinner />
                    ) : (
                      <ArrowUpwardRoundedIcon />
                    )}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="ochat-btn"
                    onClick={(e) => {
                      handleSend(e);
                    }}
                  >
                    {spinner === true ? (
                      <ButtonSpinner />
                    ) : (
                      <ArrowUpwardRoundedIcon />
                    )}
                  </Button>
                )}
                {stop && (
                  <Button
                    onClick={handleStopStream}
                    type="button"
                    className="ms-2 ochat-btn"
                  >
                    <SquareIcon />
                  </Button>
                )}
                <CustomTooltip
                  title="Upload resumes"
                  arrow
                  placement="top"
                  content={
                    <Button
                      type="button"
                      className="ms-2 ochat-btn position-relative"
                    >
                      {loading ? <ButtonSpinner /> : <AttachmentIcon />}
                      <input
                        type="file"
                        onChange={(e) => {
                          dispatch(
                            handleFileUpload({
                              files: e.target.files,
                              id: project?._id,
                            })
                          );
                          e.target.value = "";
                        }}
                        multiple
                        className="opacity-0 position-absolute w-100 top-0 bottom-0 start-0 end-0"
                      />
                    </Button>
                  }
                />
                <CustomTooltip
                  title="View resumes"
                  arrow
                  placement="top"
                  content={
                    <Button
                      type="button"
                      className="ms-2 ochat-btn"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      {" "}
                      <FilePresentIcon />
                    </Button>
                  }
                />
              </div>
            </Form.Group>
          </Form>
        </Grid>
      </Grid>
    </div>
  );
};

export default HRUpload;
