import {
  AppProjectRequest,
  DeleteProjectRequest,
  SearchProjectRequest,
} from "../../../redux/slices/AppProjectSlice";
import {
  AddImageProject,
  DeleteImageProject,
} from "../../../redux/slices/ImageProjectSlice";
import {
  AddChatProject,
  DeleteChatProject,
} from "../../../redux/slices/ChatProjectSlice";
import { BASEURL, checktoken, headers } from "../../../utils/helper";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Skeleton, Checkbox, IconButton, Chip } from "@mui/material";
import {
  AddHRProject,
  DeleteHRProject,
} from "../../../redux/slices/HRUploadSlice";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Card, Row, Col, Form, Dropdown } from "react-bootstrap";
import { useRef, useState, useEffect, useMemo } from "react";
import Toaster from "../../../components/common/Toaster";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Select from "react-dropdown-select";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import { DeleteTextToVideoProject } from "../../../redux/slices/TextToVideoSlice";
import { DeleteEmailProject } from "../../../redux/slices/EmailProjectSlice";
import { DeleteTranscribeImageProject } from "../../../redux/slices/TranscribeImageSlice";
import { DeleteTranslationProject } from "../../../redux/slices/TranslationProjectSlice";
import { DeleteSocialProject } from "../../../redux/slices/SocialProjectSlice";
import { DeleteArticleProject } from "../../../redux/slices/ArticleProjectSlice";
import { DeleteRephraseProject } from "../../../redux/slices/RephraseProjectSlice";
import { DeleteDocProject } from "../../../redux/slices/DocProjectSlice";
import { DeleteTextToVoiceProject } from "../../../redux/slices/TextToVoiceSlice";

const Projects = () => {
  const inputRef = useRef([]);
  const { ptype } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productNameRef = useRef([]);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("all");
  const [edit, setEdit] = useState(false);
  const [Index, setIndex] = useState(null);
  const [nfilter, setNFilter] = useState(-1);
  const [projects, setProjects] = useState([]);
  const filter = { value: -1, label: "newest" };
  const [projectId, setProjectId] = useState([]);
  const user = useSelector((state) => state.AuthSlice);
  const [redirectUrl, setRedirectUrl] = useState("all");
  const [selected, setSelected] = useState({
    value: "all",
    text: "All Project",
  });
  const { data: projectitems, loading } = useSelector(
    (state) => state.AppProjectSlice
  );

  const { data: isProject, ploading } = useSelector(
    (state) => state.ChatProjectSlice
  );
  const { data: isTranslationProject, loading: tloading } = useSelector(
    (state) => state.TranslationProjectSlice
  );
  const { data: isRephraseProject, loading: rloading } = useSelector(
    (state) => state.RephraseProjectSlice
  );
  const { data: isDocProject, loading: dloading } = useSelector(
    (state) => state.DocProjectSlice
  );
  const { data: isSocialProject, loading: sloading } = useSelector(
    (state) => state.SocialProjectSlice
  );
  const { data: isArticleProject, loading: aloading } = useSelector(
    (state) => state.ArticleProjectSlice
  );
  const { data: isEmailProject, loading: eloading } = useSelector(
    (state) => state.EmailProjectSlice
  );
  const { data: isHRProject, loading: hloading } = useSelector(
    (state) => state.HRUploadSlice
  );
  const { data: isImgProject, loading: iloading } = useSelector(
    (state) => state.ImageProjectSlice
  );
  const { data: isTranscribeImageProject, loading: tIloading } = useSelector(
    (state) => state.TranscribeImageSlice
  );
  const { data: TextToVideoProject, loading: tVloading } = useSelector(
    (state) => state.TextToVideoSlice
  );
  const { data: TextToVoiceProject, loading: tVoiceLoading } = useSelector(
    (state) => state.TextToVoiceSlice
  );
  const options = [
    { value: "all", text: "All Project" },
    { value: "outside", text: "ExecutivesGPT -turbo" },
    { value: "article", text: "Article Writer" },
    { value: "doc", text: "Doc Reader" },
    { value: "social", text: "Social Media" },
    { value: "translation", text: "Translation" },
    { value: "rephrase", text: "Rephrase" },
    { value: "email", text: "Email Template" },
    { value: "hr", text: "HR Uploads" },
    { value: "image", text: "Image Generation" },
    { value: "transcribeImage", text: "Image Transcription" },
    { value: "textToVideo", text: "Text To Video" },
    { value: "textToVoice", text: "Text To Voice" },
  ];

  const filterText = [
    { label: "newest", value: -1 },
    { label: "oldest", value: 1 },
  ];

  const formatDate = (dateString) => {
    return moment(dateString).format("MMMM Do YYYY, h:mm:ss a");
  };

  const RenameAction = (name, index) => {
    setEdit(true);
    setIndex(index);
    setTitle(name);
  };

  const EnterKeyPress = (e, id) => {
    UpdateProject(title, id);
  };

  const handleProjectId = (e) => {
    let updateids = [...projectId];
    if (e.target.checked) {
      updateids = [...projectId, e.target.value];
    } else {
      updateids.splice(projectId.indexOf(e.target.value), 1);
    }
    setProjectId(updateids);
  };

  const UpdateProject = (title, id) => {
    axios
      .put(
        `${BASEURL}/user/project/update/${id}`,
        { title: title.toLowerCase() },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          toast.success(res.data.message);
          setEdit(false);
          dispatch(AppProjectRequest({ type: type, filter: nfilter }));
        }
      })
      .catch((err) => {
        checktoken(err);
      });
  };

  const DeleteProject = async (id, projType) => {
    if (projType === "textToVideo") {
      console.log("DeleteProject", projType);
      TextToVideoProject?._id === id && dispatch(DeleteTextToVideoProject());
    }
    if (projType === "textToVoice") {
      console.log("DeleteProject", projType);
      TextToVoiceProject?._id === id && dispatch(DeleteTextToVoiceProject());
    }

    if (projType === "image") {
      console.log("DeleteProject", projType);
      isImgProject?._id === id && dispatch(DeleteImageProject());
    }

    if (projType === "hr") {
      console.log("DeleteProject", projType);
      isHRProject?._id === id && dispatch(DeleteHRProject());
    }

    if (projType === "email") {
      console.log("DeleteProject", projType);
      isEmailProject?._id === id && dispatch(DeleteEmailProject());
    }

    if (projType === "rephrase") {
      console.log("DeleteProject", projType);
      isRephraseProject?._id === id && dispatch(DeleteRephraseProject());
    }

    if (projType === "transcribeImage") {
      console.log("DeleteProject", projType);
      isTranscribeImageProject?._id === id &&
        dispatch(DeleteTranscribeImageProject());
    }
    if (projType === "translation") {
      console.log("DeleteProject", projType);
      isTranslationProject?._id === id && dispatch(DeleteTranslationProject());
    }

    if (projType === "social") {
      console.log("DeleteProject", projType);
      isSocialProject?._id === id && dispatch(DeleteSocialProject());
    }

    if (projType === "doc") {
      console.log("DeleteProject", projType);
      isDocProject?._id === id && dispatch(DeleteDocProject());
    }

    if (projType === "article") {
      console.log("DeleteProject", projType);
      // isArticleProject?._id === id && dispatch(DeleteArticleProject());
    }

    if (projType === "chat") {
      console.log("DeleteProject", projType);
      isProject?._id === id && dispatch(DeleteChatProject());
    }

    await dispatch(
      DeleteProjectRequest({
        id: projectId.length === 0 ? [id] : projectId,
        type: projType,
      })
    );
    dispatch(AppProjectRequest({ type: type, filter: nfilter }));
  };

  const SearchProject = (e) => {
    if (e.target.value !== "") {
      dispatch(
        SearchProjectRequest({
          type: type,
          text: e.target.value,
          filter: nfilter,
        })
      );
    } else {
      dispatch(AppProjectRequest({ type: type, filter: nfilter }));
    }
  };

  //   useEffect(() => {

  // }, [navigate])

  // console.log("loading.../", loading);

  //   useEffect(() => {
  // }, [loading])

  // AppProjectSlice

  useMemo(() => {
    if (projectitems && Object.keys(projectitems)?.length > 0) {
      // console.log("projectitems.../", projectitems);
      setProjects(projectitems);
    } else {
      setProjects([]);
    }
    // console.log("projectitems.../", projectitems);
    return projects;
  }, [projectitems]);

  useEffect(() => {
    if (user?.currentplan) {
      if (ptype) {
        setType(ptype);
        setSelected({
          value: ptype === "chat" ? "outside" : ptype,
          text:
            ptype === "all"
              ? "All Project"
              : ptype === "chat"
              ? "ExecutivesGPT -turbo"
              : ptype === "article"
              ? "Article Writer"
              : ptype === "doc"
              ? "Doc Reader"
              : ptype === "social"
              ? "Social Media"
              : ptype === "email"
              ? "Email Template"
              : ptype === "hr"
              ? "Hr Uploads"
              : ptype === "image"
              ? "Image Generation"
              : ptype === "outside"
              ? "ExecutivesGPT -turbo"
              : ptype === "translation"
              ? "translation"
              : ptype === "rephrase"
              ? "rephrase"
              : ptype === "textToVideo"
              ? "Text To Video"
              : "",
        });
        dispatch(AppProjectRequest({ type: ptype, filter: nfilter }));
      } else {
        setSelected({
          value: "all",
          text: "All Project",
        });
        setProjects([]);
        dispatch(AppProjectRequest({ type: type, filter: nfilter }));
      }
    }
  }, [navigate]);

  return (
    <div id="user-projects" className="p-3" style={{ height: "100vh" }}>
      <Toaster />
      <Col sm={12}>
        <div className="mb-3 d-flex input-field align-items-start justify-content-between">
          <Form.Control
            type="text"
            className="custom-field"
            placeholder="Search projects"
            style={{ maxWidth: "300px" }}
            onKeyUp={(e) => {
              SearchProject(e);
            }}
          />
          <div className="d-flex">
            <Select
              style={{ width: "220px" }}
              values={[selected]}
              options={options}
              searchable={false}
              placeholder="Select"
              labelField="text"
              valueField="value"
              onChange={(value) => {
                if (value.map(({ value }) => value)[0] === "outside") {
                  setType("chat");
                  dispatch(
                    AppProjectRequest({ type: "chat", filter: nfilter })
                  );
                } else {
                  setType(value.map(({ value }) => value)[0]);
                  dispatch(
                    AppProjectRequest({
                      type: value.map(({ value }) => value)[0],
                      filter: nfilter,
                    })
                  );
                }
                setRedirectUrl(value.map(({ value }) => value)[0]);
              }}
              className="multiselect-dropdown"
            />
            &nbsp;
            <Select
              style={{ width: "150px" }}
              values={[filter]}
              options={filterText}
              labelField="label"
              valueField="value"
              searchable={false}
              className="text-capitalize multiselect-dropdown"
              onChange={(values) => {
                setNFilter(values.map(({ value }) => value)[0]);
                dispatch(
                  AppProjectRequest({
                    type: type,
                    filter: values.map(({ value }) => value)[0],
                  })
                );
              }}
            />
          </div>
        </div>
      </Col>
      {loading === true && projects.length < 1 && (
        <Row>
          <Col md={4} className="m-auto mb-3">
            <Skeleton variant="rounded" height={150} />
          </Col>
          <Col md={4} className="m-auto mb-3">
            <Skeleton variant="rounded" height={150} />
          </Col>
          <Col md={4} className="m-auto mb-3">
            <Skeleton variant="rounded" height={150} />
          </Col>
          <Col md={4} className="m-auto mb-3">
            <Skeleton variant="rounded" height={150} />
          </Col>
          <Col md={4} className="m-auto mb-3">
            <Skeleton variant="rounded" height={150} />
          </Col>
          <Col md={4} className="m-auto mb-3">
            <Skeleton variant="rounded" height={150} />
          </Col>
        </Row>
      )}
      {projects.length > 0 && loading === false && (
        <Row>
          {projects.map((item, index) => {
            return (
              <Col key={index} lg={4} md={6} className="mb-3">
                <Card>
                  <Card.Body className="project-card position-relative">
                    <div
                      className={`${
                        item.type === "hr" && ""
                      } d-flex flex-row mb-3 align-items-center`}
                    >
                      <h5 className={`m-0 flex-grow-1`}>
                        {edit === false ? (
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              if (type === "chat") {
                                dispatch(AddChatProject(item));
                                navigate(`/user/chat/${redirectUrl}`);
                              }
                              if (type === "article") {
                                navigate(
                                  `/user/chat/history/${item._id}/${item.type}`
                                );
                              }
                              if (type === "social") {
                                navigate(
                                  `/user/chat/history/${item._id}/${item.type}`
                                );
                              }
                              if (type === "translation") {
                                navigate(
                                  `/user/chat/history/${item._id}/${item.type}`
                                );
                              }
                              if (type === "doc") {
                                navigate(`/user/chat/history/doc/${item._id}`);
                              }
                              if (type === "email") {
                                navigate(
                                  `/user/chat/history/${item._id}/${item.type}`
                                );
                              }
                              if (type === "hr") {
                                dispatch(AddHRProject(item));
                                navigate(`/user/chat/hruploads`);
                              }
                              if (type === "image") {
                                dispatch(AddImageProject(item));
                                navigate(
                                  `/user/chat/history/${item._id}/${item.type}`
                                );
                              }
                              if (type === "textToVideo") {
                                navigate(
                                  `/user/chat/history/${item._id}/${item.type}`
                                );
                              }
                              if (type === "all") {
                                if (item.type === "chat") {
                                  dispatch(AddChatProject(item));
                                  navigate(`/user/chat/outside`);
                                } else if (item.type === "doc") {
                                  navigate(
                                    `/user/chat/history/doc/${item._id}`
                                  );
                                } else if (item.type === "hr") {
                                  dispatch(AddHRProject(item));
                                  navigate(`/user/chat/history/hr/${item._id}`);
                                } else if (item.type === "image") {
                                  dispatch(AddImageProject(item));
                                  navigate(
                                    `/user/chat/history/image/${item._id}`
                                  );
                                } else {
                                  navigate(
                                    `/user/chat/history/${item._id}/${item.type}`
                                  );
                                }
                              }
                            }}
                            className="text-decoration-none project-name real-name"
                            ref={(el) => (productNameRef.current[index] = el)}
                          >
                            {item.title}
                            <Chip
                              label={item.type}
                              color="success"
                              className="ml-1 text-capitalize"
                            />
                          </Link>
                        ) : Index === index ? (
                          <Form.Control
                            type="text"
                            name="title"
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              e.key === "Enter" &&
                                !e.shiftKey &&
                                e.target.value.trim() !== "" &&
                                EnterKeyPress(e, item._id, index);
                            }}
                            onBlur={(e) => {
                              EnterKeyPress(e, item._id, index);
                            }}
                            ref={(el) => (inputRef.current[index] = el)}
                          />
                        ) : (
                          <Link
                            className="text-decoration-none project-name"
                            ref={(el) => (productNameRef.current[index] = el)}
                          >
                            {item.title}
                          </Link>
                        )}
                      </h5>
                      <Checkbox
                        value={item._id}
                        onChange={handleProjectId}
                        className=""
                        color="success"
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center project-options">
                      <CalendarMonthIcon style={{ fontSize: "15px" }} />
                      <span className="mx-1" style={{ fontSize: "12px" }}>
                        {formatDate(item.createdAt)}
                      </span>
                      <Dropdown className="ms-auto">
                        <Dropdown.Toggle
                          as="div"
                          className="border-0 project-dropdown btn-light p-0"
                          id={`dropdown-basic-${index}`}
                        >
                          <IconButton
                            size="small"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            color="inherit"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="project-abbr border-0 shadow end-0 p-0">
                          <Dropdown.Item
                            onClick={() => DeleteProject(item?._id, item?.type)}
                          >
                            <DeleteIcon className="me-1" />
                            Delete
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              RenameAction(item.title, index);
                            }}
                          >
                            <EditIcon className="me-1" />
                            Rename
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
      {projects.length < 1 && loading === false && (
        <>
          <div className="h-96 w-full flex justify-center items-center">
            <p className="text-3xl font-bold">No data available</p>
          </div>
        </>
      )}
    </div>
  );
};

export default Projects;
