import { SaveChatRequest } from "../../../redux/slices/ImageProjectSlice";
import { useState, useEffect, useRef } from "react";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import CustomTooltip from "../../../components/common/CustomTooltip";
import {
  BASEURL,
  checktoken,
  headers,
  TextToVidActors,
  TextToVidLangs,
} from "../../../utils/helper";
import Select from "react-dropdown-select";
import { Form, Col } from "react-bootstrap";
import ErrorIcon from "@mui/icons-material/Error";

import {
  IconButton,
  Button,
  FormGroup,
  Typography,
  Stack,
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import Toaster from "../../../components/common/Toaster";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import ProjectComponent from "./ProjectComponent";
import { toast } from "react-toastify";
import axios from "axios";
import IOSSwitch from "../../../components/chat/IosSwitch";
import { SaveTextToVoiceReq } from "../../../redux/slices/SaveTextToVoiceSlice";

const TextToVoice = () => {
  const chatBlock = useRef();
  const audioRef = useRef(null);
  const genAudioRef = useRef(null);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.AuthSlice);
  const { data: project } = useSelector((state) => state.TextToVoiceSlice);
  const [chat, setChat] = useState([]);
  const [isGenerated, setIsGenerated] = useState(false);
  const [language, setLanguage] = useState();
  const [languageActor, setLanguageActor] = useState([]);
  const [content, setContent] = useState("");
  const [generatedVideo, setGeneratedVideo] = useState("");
  const [height, setHeight] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [videoName, setVideoName] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const [videoScriptLength, setVideoScriptLength] = useState("");
  const [genScriptBool, setGenScriptBool] = useState(false);
  const [getNarationLoading, setGetNarationLoading] = useState(false);
  const [aiScript, setAiScript] = useState("");
  const [isGenAudioPlaying, setIsGenAudioPlaying] = useState(false);
  const selectRef = useRef(null);
  const langRef = useRef(null);
  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const audio = genAudioRef.current;
    const handleAudioEnd = () => {
      setIsGenAudioPlaying(false);
    };

    if (audio) {
      audio.addEventListener("ended", handleAudioEnd);
    }

    return () => {
      if (audio) {
        audio.removeEventListener("ended", handleAudioEnd);
      }
    };
  }, [genAudioRef, isGenAudioPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    const handleAudioEnd = () => {
      setIsPlaying(false);
    };
    if (audio) {
      audio.addEventListener("ended", handleAudioEnd);
    }
    return () => {
      if (audio) {
        audio.removeEventListener("ended", handleAudioEnd);
      }
    };
  }, [audioRef, isPlaying]);

  const handleSend = async (e, edit) => {
    if (language === null || language === undefined) {
      toast.error("Please select any language!");
      return false;
    }
    if (content === "" && !genScriptBool) {
      toast.error("Content is empty!");
      return false;
    }
    if (aiScript === "" && genScriptBool) {
      toast.error("Content is empty!");
      return false;
    }
    if (videoName === "") {
      toast.error("videoName is empty!");
      return false;
    }
    e.preventDefault();
    setGeneratedVideo("");
    var languageObj =
      languageActor != null || languageActor != undefined
        ? languageActor?.[0]
        : getLangActors()?.[0];
    const contentStr = genScriptBool ? aiScript : content;

    setSpinner(true);
    axios
      .post(
        `${BASEURL}/user/text-voice`,
        {
          text_voice: contentStr,
          language: languageObj,
          edit,
          videoName,
        },
        {
          headers: headers(),
        }
      )
      .then(async (res) => {
        if (res?.data?.success) {
          setGeneratedVideo(res?.data?.url);
          setIsGenerated(true);
          setChat([
            ...chat,
            {
              prompt: contentStr,
              type: "video",
              project: project?._id,
              video: res?.data?.url,
            },
          ]);
          const saveprompt = {
            prompt: contentStr,
            type: "textToVoice",
            airesponse: res?.data?.url,
            project: project._id,
            language: language[0].value,
          };
          const saveChat = await dispatch(SaveChatRequest(saveprompt));
          console.log(
            "saveChat",
            saveChat.payload.data[saveChat.payload.data.length - 1]
          );

          dispatch(
            SaveTextToVoiceReq({
              prompt: contentStr,
              language: language[0].value,
              airesponse: res?.data?.url,
              history_id: project._id,
              language: language.value,
            })
          );
        }
        setSpinner(false);
        scrollChat();
      })
      .catch((err) => {
        setSpinner(false);
        checktoken(err);
        if (err?.response?.data?.error) {
          toast.error(err.response.data.error);
        }
      });
  };

  const handleGenScript = async (e) => {
    e.preventDefault();
    if (!videoScriptLength) {
      toast.error("Please Enter Time!");
      return false;
    }
    if (!topic) {
      toast.error("Please Enter Topic!");
      return false;
    }
    try {
      setGetNarationLoading(true);
      const response = await axios.post(
        `${BASEURL}/user/get-narration`,
        { time: videoScriptLength, idea: topic, description },
        {
          headers: headers(),
        }
      );
      setAiScript(response?.data?.response);
      setGetNarationLoading(false);
    } catch (error) {
      setGetNarationLoading(false);
    }
  };

  const scrollChat = () => {
    setTimeout(function () {
      const objDiv = chatBlock.current;
      if (objDiv !== null && objDiv !== undefined) {
        objDiv.scrollTo({
          top: objDiv.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    });
  };

  const getProject = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/project/${project?._id}/textToVideo/latest-prompts`,
        { headers: headers() }
      );
      setGeneratedVideo(response?.data?.data?.airesponse);
      setContent(response?.data?.data?.prompt);
      setIsGenerated(true);
      const lang = TextToVidLangs.find((item) => {
        return item?.value == response?.data?.data?.language;
      });
    } catch (error) {
      return error;
    }
  };

  const getLangActors = () => {
    if (language?.[0]) {
      const filter = TextToVidActors.filter(
        (item) => item.value === language?.[0]?.value
      );
      return filter;
    } else return [];
  };

  const resetForm = () => {
    setIsGenerated(false);
    setGeneratedVideo(null);
    setContent("");
    setVideoName("");
    setAiScript("");
    setGetNarationLoading(false);
    setDescription("");
    setVideoScriptLength("");
    setTopic("");
    selectRef.current?.clearAll();
    langRef.current?.clearAll();
    setChat([]);
  };

  const handleDownload = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName || "download.mp3");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  useEffect(() => {
    setHeight(document.getElementById("chat-layout").offsetHeight);
    if (project?._id && user?.currentplan?.[0]) {
      resetForm();
    }
  }, [project]);

  return (
    <div
      className="flex flex-col"
      style={{ height: `calc(100vh - ${height}px)` }}
    >
      {user?.currentplan?.[0] && <ProjectComponent />}
      <Toaster />
      <div
        id="chat-body"
        className="grow chat-block overflow-y-auto h-full p-[20px]"
        ref={chatBlock}
      >
        <div className="mb-3">
          <div className="btn-thm-1 d-flex align-items-center flex-wrap">
            <span>
              <TranslateIcon />
            </span>
            <Select
              ref={langRef}
              className="shadow-sm multiselect-dropdown me-2"
              style={{ width: "200px" }}
              options={TextToVidLangs}
              searchable={true}
              placeholder="Choose a language"
              labelField="value"
              valueField="value"
              onChange={(value) => {
                setLanguageActor([]);
                setLanguage(value);
              }}
            />
            {language && (
              <Select
                ref={selectRef}
                className="shadow-sm multiselect-dropdown me-2"
                style={{ width: "200px" }}
                options={getLangActors()}
                values={[...languageActor]}
                placeholder="Choose Voice"
                labelField="optionLabel"
                valueField="actor"
                onChange={(value) => {
                  setLanguageActor(value);
                }}
              />
            )}

            {languageActor?.length > 0 && (
              <>
                <CustomTooltip
                  title="Play sample audio"
                  arrow
                  placement="right"
                  content={
                    <div className="mr-4">
                      <button
                        className="bg-primary-main p-2 rounded-md"
                        onClick={handlePlayPause}
                      >
                        {isPlaying ? (
                          <>
                            <img src="/pause.svg" alt="" width={20} />
                          </>
                        ) : (
                          <>
                            <img src="/play.svg" alt="" width={20} />
                          </>
                        )}
                      </button>
                      <audio
                        ref={audioRef}
                        src={languageActor?.[0]?.audioSample}
                      />
                    </div>
                  }
                />
              </>
            )}
            <div className="mr-5">
              <Form.Control
                type="text"
                className="custom-field"
                style={{
                  maxWidth: "300px",
                  minHeight: "20px",
                  paddingLeft: "10px",
                }}
                value={videoName}
                onChange={(e) => {
                  setVideoName(e.target.value);
                }}
                placeholder="Enter Audio name"
              />
            </div>

            {isGenerated ? (
              <Button
                type="submit"
                className="bg-primary-light hover:bg-primary-main text-white"
                onClick={(e) => {
                  resetForm();
                }}
              >
                reset
              </Button>
            ) : (
              <Button
                onClick={(e) => {
                  handleSend(e, false);
                }}
                type="submit"
                className="bg-primary-light hover:bg-primary-main text-white"
              >
                Generate
                {spinner === true && <ButtonSpinner />}
              </Button>
            )}
          </div>
        </div>
        <div className="flex justify-center w-1/2">
          <FormGroup>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Enter Script</Typography>
              <IOSSwitch
                sx={{ m: 1 }}
                checked={genScriptBool}
                disabled={getNarationLoading}
                onChange={() => setGenScriptBool((o) => !o)}
              />
              <Typography>Idea of Narration</Typography>
            </Stack>
          </FormGroup>
        </div>
        {genScriptBool ? (
          <>
            <Col sm={12} md={6}>
              {!aiScript ? (
                <>
                  <div>
                    <Form.Group className="mb-3 input-field">
                      <div className="d-flex justify-content-between align-items-center">
                        <CustomTooltip
                          title="Enter Topic"
                          arrow
                          placement="left"
                          content={
                            <Form.Label>
                              Topic
                              <ErrorIcon className="text-body-tertiary fs-6" />
                            </Form.Label>
                          }
                        />
                      </div>
                      <Form.Control
                        as="textarea"
                        placeholder="Artificial Intelligence in Copywriting"
                        className="custom-field custom-textarea"
                        value={topic}
                        rows={2}
                        onChange={(e) => {
                          setTopic(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 input-field">
                      <div className="d-flex justify-content-between align-items-center">
                        <CustomTooltip
                          title="Enter More Details about the video"
                          arrow
                          placement="left"
                          content={
                            <Form.Label>
                              Be more specific
                              <ErrorIcon className="text-body-tertiary fs-6" />
                            </Form.Label>
                          }
                        />
                      </div>
                      <Form.Control
                        as={"textarea"}
                        type="text"
                        className="custom-field custom-textarea"
                        placeholder="Be more specific"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 input-field">
                      <div className="d-flex justify-content-between align-items-center">
                        <CustomTooltip
                          title="Enter the length of the Audio in seconds"
                          arrow
                          placement="left"
                          content={
                            <Form.Label>
                              Audio Length
                              <ErrorIcon className="text-body-tertiary fs-6" />
                            </Form.Label>
                          }
                        />
                      </div>
                      <Form.Control
                        type="number"
                        className="custom-field"
                        placeholder="30"
                        value={videoScriptLength}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === "" || !isNaN(value)) {
                            setVideoScriptLength(value);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (
                            e.key.length === 1 &&
                            /\D/.test(e.key) && // Prevent non-digit input
                            e.key !== "Backspace" &&
                            e.key !== "Tab" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Group>
                    <Button
                      onClick={(e) => {
                        handleGenScript(e, false);
                      }}
                      type="submit"
                      disabled={getNarationLoading}
                      className="bg-primary-light hover:bg-primary-main text-white"
                    >
                      Get Narration
                      {getNarationLoading === true && <ButtonSpinner />}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Form>
                    <Form.Group className="my-3 custom-field">
                      <textarea
                        className="border p-2 border-gray-600"
                        name=""
                        id=""
                        placeholder=""
                        rows={12}
                        cols={100}
                        onChange={(e) => setAiScript(e.target.value)}
                        value={aiScript}
                      ></textarea>
                    </Form.Group>
                  </Form>
                </>
              )}
            </Col>
          </>
        ) : (
          <>
            <Col sm={12} md={6}>
              <Form>
                <Form.Group className="my-3 custom-field">
                  <textarea
                    className="border p-2 border-gray-600"
                    name=""
                    id=""
                    placeholder="Enter Your Script here..."
                    rows={12}
                    cols={100}
                    onChange={(e) => setContent(e.target.value)}
                    value={content}
                  ></textarea>
                </Form.Group>
              </Form>
            </Col>
          </>
        )}

        {generatedVideo && (
          <>
            <div className="d-flex flex-grow-1 align-items-end justify-content-between">
              <div
                className="text-center mx-auto position-relative dalle-image"
                style={{ maxWidth: "600px" }}
              >
                <div className="mt-10  border border-gray-200">
                  <div className="w-full mt-8 mb-4">
                    <video className="w-full" controls>
                      <source src={generatedVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                <div className="position-absolute top-0 start-0 end-0 p-2 text-start">
                  <a
                    onClick={() => {
                      handleDownload(
                        generatedVideo,
                        videoName + "-" + Date.now() + ".mp3"
                      );
                    }}
                  >
                    <IconButton>
                      <DownloadIcon />
                    </IconButton>
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TextToVoice;
