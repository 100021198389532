// TextToVoiceSlice

import { headers, BASEURL, checktoken } from "../../utils/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const TextToVoiceProjectRequest = createAsyncThunk(
  "TextToVoiceProjectRequest",
  async (type) => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/latest/project?type=${type}`,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const CreateTextToVoiceProject = createAsyncThunk(
  "CreateTextToVoiceProject",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/project/create`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const SaveTextToVoiceHistory = createAsyncThunk(
  "SaveTextToVoiceHistory",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/create/chat/history`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const TextToVoiceSlice = createSlice({
  name: "TextToVoiceProject",
  initialState: {
    data: {},
    error: null,
    loading: false,
  },
  reducers: {
    AddTextToVoiceProject: (state, action) => {
      state.data = action.payload;
      return state;
    },
    DeleteTextToVoiceProject: (state, action) => {
      state.data = {};
    },
  },
  extraReducers(builder) {
    builder.addCase(TextToVoiceProjectRequest.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(TextToVoiceProjectRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(TextToVoiceProjectRequest.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase(CreateTextToVoiceProject.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(CreateTextToVoiceProject.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(CreateTextToVoiceProject.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase("LOGOUT", () => this.initialState);
  },
});

export const { AddTextToVoiceProject, DeleteTextToVoiceProject } =
  TextToVoiceSlice.actions;
export default TextToVoiceSlice.reducer;
