import React, { Suspense, useRef } from "react";
import LandingSidebar from "./LandingSidebar";
// import { headers, checktoken } from "../../utils/helper";
import { Box, Drawer, Grid, IconButton } from "@mui/material";

// import { addAuth } from "../../redux/slices/AuthSlice";
import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { icons } from "../../../assets/js/icons";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Cookies } from "react-cookie";
// import Block from "../common/Block";
import Block from "../../../components/common/Block";
import Header from "./Header";
import axios from "axios";
// import { PopupContext } from "../../context/PopupContext";
import { PopupContext } from "../../../context/PopupContext";
import LandingPrompt from "./LandingPrompt";
import MarkdownViewer from "../../../components/chat/MarkdownViewer";

const Landing = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [height, setHeight] = useState("");

  const cookie = cookies.get("auth");
  const { popupState, setpopupState } = useContext(PopupContext);
  const [open, setOpen] = useState(false);
  const [stop, setStop] = useState(false);
  const [width, setWidth] = useState(1200);
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", (e) => {
      setWidth(e.target.innerWidth);
    });
  }, []);

  useEffect(() => {
    setHeight(document.getElementById("chat-layout").offsetHeight);
  }, []);

  return (
    <>
      <Fragment>
        {stop && <Block />}
        <Box id="Chat">
          {width > 900 ? (
            <div className="chat-sidebar overflow-auto">
              <LandingSidebar id={id} setId={setId} />
            </div>
          ) : (
            <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
              <LandingSidebar closeFun={() => setOpen(false)} />
            </Drawer>
          )}
          <div className="">
            <Box
              sx={{
                marginLeft: { md: "250px" },
                width: { md: "calc(100% - 250px)" },
              }}
            >
              <main className="flex  flex-col h-[100vh]">
                <Box
                  className="flex bg-white sticky-sm-top top-0"
                  id="chat-layout"
                  style={{ zIndex: "1" }}
                >
                  <IconButton
                    className="flex md:hidden self-start position-absolute z-10"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    {<icons.MuiMenu />}
                  </IconButton>
                  <Box className="grow max-h-[100%]">
                    <Header />
                  </Box>
                </Box>
                <div className="grow">
                  <Outlet />

                  {/* <>
                      <LandingPrompt id={id} />
                    </> */}
                </div>
              </main>
            </Box>
          </div>
        </Box>
      </Fragment>
    </>
  );
};

export default Landing;
