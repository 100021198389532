import {
  SocialContentRequest,
  SocialContentUpdateRequest,
} from "../../../redux/slices/SocialProjectSlice";
import { SaveChatRequest } from "../../../redux/slices/ChatPromptSlice";
import { WordCountRequest } from "../../../redux/slices/CounterSlice";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import { BASEURL, checktoken, headers } from "../../../utils/helper";
import CustomTooltip from "../../../components/common/CustomTooltip";
import MarkdownViewer from "../../../components/chat/MarkdownViewer";
import { ToneListRequest } from "../../../redux/slices/ToneSlice";
import FormSkelton from "../../../components/common/FormSkelton";
import { useState, useEffect, useMemo, Fragment, useRef } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Toaster from "../../../components/common/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { Form, Stack, Card } from "react-bootstrap";
import ProjectComponent from "./ProjectComponent";
import Select from "react-dropdown-select";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import DOMPurify from "dompurify";
import { useLocation } from "react-router-dom";
import axios from "axios";

const SocialMedia = () => {
  const dispatch = useDispatch();
  const [limitError, setLimitError] = useState(false);
  const location = useLocation();
  const { historyId } = location.state || { historyId: null }; // Destructure historyId from state

  const [projectData, setProjectData] = useState();
  const [tone, setTone] = useState("");
  const [words, setWords] = useState("");
  const [stop, setStop] = useState(false);
  const [content, setContent] = useState([]);
  const [hashtags, setHashtags] = useState("");
  const [tonelist, setToneList] = useState([]);
  const [audience, setAudience] = useState("");
  const [rows, setRows] = useState(1);

  const [spinner, setSpinner] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const user = useSelector((state) => state.AuthSlice);
  const [controller, setController] = useState(new AbortController());
  const { data: tones, loading } = useSelector((state) => state.ToneSlice);
  const { data: project } = useSelector((state) => state.SocialProjectSlice);
  const [title, setTitle] = useState("");
  // console.log("tones.../", tones);
  // console.log("toneList.../", tonelist);
  const handleStopStream = (e) => {
    setStop(false);
    setTone("");
    setWords("");
    setTitle("");
    setAudience("");
    setHashtags("");
    controller.abort();
    setController(new AbortController());
  };

  const selectRef = useRef(null);
  const [projId, setProjId] = useState(null);
  useEffect(() => {
    if (project?._id) setProjId(project?._id);
  }, []);

  useEffect(() => {
    if (project) {
      if (project._id && projId !== project._id) {
        setWords("");
        setTitle("");
        setAudience("");
        setHashtags("");
        setContent([]);
        setIsComplete(false);
        setRows(1);
        selectRef.current.clearAll();
      }
    }
  }, [project]);

  const handleCopy = (content) => {
    content
      ? toast.success("Response copied to clipboard!")
      : toast.error("Response not found !");
  };

  useMemo(() => {
    if (tones && Object.keys(tones)?.length) {
      const values = [];
      tones.forEach((item) => {
        values.push({
          label: `${item.imoji} ${item.title}`,
          value: item.title,
        });
      });
      setToneList(values);
      return tonelist;
    }
  }, [tones]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    setLimitError(false);

    try {
      const formData = { tone, words, title, audience, hashtags };
      dispatch(WordCountRequest());
      const message = JSON.stringify(formData);
      const response = await fetch(
        `${BASEURL}/user/generate/social/media/captions`,
        {
          method: "POST",
          signal: controller.signal,
          responseType: "stream",
          headers: headers(),
          body: message,
        }
      );
      if (response.status === 500) {
        // console.log("response.status === 500");

        // // setLimitError(true);
        toast.error("Daily limit exceeded");
        setSpinner(false);
        setStop(true);
        throw new Error("Daily limit exceeded");
      }
      //eslint-disable-next-line
      const readData = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      setSpinner(false);
      setStop(true);
      let aiRes = "";
      while (true) {
        const { done, value } = await readData.read();
        if (done) {
          break;
        }
        aiRes += value;
        setContent([...content, { airesponse: aiRes }]);
      }
      setStop(false);
      setIsComplete(true);
      const saveprompt = {
        prompt: title,
        type: "social",
        airesponse: aiRes,
        project: project._id,
      };
      const saveChat = await dispatch(SaveChatRequest(saveprompt));
      console.log(
        "saveChat",
        saveChat.payload.data[saveChat.payload.data.length - 1]
      );
      // if (historyId)
      //   dispatch(
      //     SocialContentUpdateRequest({
      //       id: historyId,
      //       tone: tone,
      //       words: +words,
      //       title: title,
      //       airesponse: aiRes,
      //       audience: audience,
      //       hashtags: +hashtags,
      //       history_id:
      //         saveChat.payload.data[saveChat.payload.data.length - 1]._id,
      //     })
      //   );
      // else
      dispatch(
        SocialContentRequest({
          tone: tone,
          words: +words,
          title: title,
          airesponse: aiRes,
          audience: audience,
          hashtags: +hashtags,
          history_id:
            saveChat.payload.data[saveChat.payload.data.length - 1]._id,
        })
      );
      dispatch(WordCountRequest());
    } catch (err) {
      setSpinner(false);
      checktoken(err);
      console.log(err);
      if (err?.response?.data?.error) {
        toast.error(err.response.data.error);
      }
    }
  };

  useMemo(() => {
    setContent([]);
    return content;
  }, [project]);

  const getProject = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/get/social/${historyId}`,
        { headers: headers() }
      );
      // return response.data
      console.log(response.data.data);
      setTitle(response.data.data.title);
      setAudience(response.data.data.audience);
      setWords(response.data.data.words);
      setHashtags(response.data.data.hashtags);
      setTone(response.data.data.tone);
      // setTone("66220c42626b7a1a9f72021d");
    } catch (error) {
      checktoken(error);
      return error;
    }
  };
  const handleSelectChange = (values) => {
    setTone(values[0]?.value);
  };

  useEffect(() => {
    if (title.length >= 0 && title.length < 120) setRows(1);
    else if (title.length >= 120 && title.length < 240) setRows(2);
    else if (title.length >= 240 && title.length < 360) setRows(3);
    else if (title.length >= 360 && title.length < 480) setRows(4);
    else if (title.length >= 480 && title.length < 600) setRows(5);
    else if (title.length > 600) setRows(6);
  }, [title]);

  useEffect(() => {
    dispatch(ToneListRequest());
    dispatch(WordCountRequest());
    if (historyId) getProject();
  }, []);

  return (
    <div className="h-100">
      {user?.currentplan && <ProjectComponent />}
      <Toaster />
      <Stack className="h-100">
        <div
          className={`mx-auto p-4 rounded border my-5 ${
            !content?.length ? "col-md-6 col-11" : "col-sm-11"
          }`}
        >
          {loading && <FormSkelton />}
          {!content?.length && !loading && (
            <Form
              onSubmit={(e) => {
                user?.currentplan ? handleSubmit(e) : e.preventDefault();
              }}
            >
              <Form.Group className="mb-3 input-field">
                <CustomTooltip
                  // title="راجع به چه موضوعی بنویسم؟"
                  arrow
                  placement="left"
                  content={
                    <Form.Control
                      as="textarea"
                      placeholder="What do you want me to write about?"
                      className="custom-field custom-textarea"
                      value={title}
                      rows={rows}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      onFocus={() => {
                        // setTitle("");
                      }}
                    />
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3 input-field">
                <CustomTooltip
                  // title="مخاطب"
                  placement="left"
                  arrow
                  content={
                    <Form.Control
                      type="text"
                      placeholder="Target audience:"
                      className="custom-field"
                      value={audience}
                      onChange={(e) => {
                        setAudience(e.target.value);
                      }}
                    />
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3 input-field">
                <CustomTooltip
                  // title="تعداد کلمات"
                  arrow
                  placement="left"
                  content={
                    <Form.Control
                      type="text"
                      placeholder="Number of words:"
                      className="custom-field"
                      value={words}
                      onChange={(e) => {
                        setWords(e.target.value);
                      }}
                    />
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3 input-field">
                <CustomTooltip
                  // title="تعداد هشتگ"
                  arrow
                  placement="left"
                  content={
                    <Form.Control
                      type="text"
                      placeholder="Number of hashtags:"
                      className="custom-field"
                      value={hashtags}
                      onChange={(e) => {
                        setHashtags(e.target.value);
                      }}
                    />
                  }
                />
              </Form.Group>
              <CustomTooltip
                // title="تعداد کلمات"
                arrow
                placement="left"
                content={
                  <Form.Group className="mb-3 custom-field">
                    <Select
                      ref={selectRef}
                      style={{ height: "50px", fontSize: "16px" }}
                      className="text-capitalize multiselect-dropdown"
                      options={tonelist}
                      searchable={false}
                      placeholder="Select Tone"
                      labelField="label"
                      valueField="value"
                      values={tonelist.filter(
                        (toneItem) => toneItem.value === tone
                      )}
                      onChange={handleSelectChange}
                    />
                  </Form.Group>
                }
              />
              {!audience || !words || !hashtags || !title || !tone ? (
                <Button
                  type="button"
                  disabled
                  className="bg-primary-light hover:bg-primary-main text-white"
                >
                  Generate content{" "}
                </Button>
              ) : (
                <CustomTooltip
                  // title="محتوی را تولید کن"
                  arrow
                  placement="left"
                  content={
                    <Button
                      type="submit"
                      className="bg-primary-light hover:bg-primary-main text-white"
                    >
                      Generate content {spinner === true && <ButtonSpinner />}
                    </Button>
                  }
                />
              )}
              {limitError && (
                <div className="mt-3">
                  <p className="font-bold text-red-500">Daily limit exceeded</p>
                </div>
              )}
            </Form>
          )}
          <Fragment>
            {content.length > 0 && (
              <Card className="border-0">
                <Card.Title> {title}</Card.Title>
                <Card.Body
                  className="p-0"
                  style={{ whiteSpace: "break-spaces" }}
                >
                  {content.map((item, index) => (
                    <MarkdownViewer
                      className="answer-content"
                      content={DOMPurify.sanitize(item.airesponse)}
                    />
                  ))}
                  {stop && isComplete === false && (
                    <div className="mt-3 d-flex align-items-center justify-content-between">
                      <Button
                        type="button"
                        className="bg-primary-light hover:bg-primary-main text-white"
                        onClick={handleStopStream}
                      >
                        stop
                      </Button>
                    </div>
                  )}
                  {stop === false && isComplete === false && (
                    <Button
                      type="button"
                      className="bg-primary-light hover:bg-primary-main text-white"
                      onClick={() => {
                        setContent([]);
                        setIsComplete(false);
                        setTitle("");
                        setTone("");
                        setWords("");
                        setHashtags("");
                        setAudience("");
                      }}
                    >
                      Reset
                    </Button>
                  )}
                  {isComplete === true && (
                    <div className="mt-3 d-flex align-items-center justify-content-between">
                      <Button
                        className="bg-primary-light hover:bg-primary-main text-white"
                        type="button"
                        onClick={() => {
                          setContent([]);
                          setIsComplete(false);
                          setTitle("");
                          setTone("");
                          setWords("");
                          setHashtags("");
                          setAudience("");
                        }}
                      >
                        Reset
                      </Button>
                      <CopyToClipboard text={content[0].airesponse}>
                        <Button
                          className="d-flex align-items-center primary-blue-btn ff-lato"
                          onClick={() => handleCopy(content[0].airesponse)}
                        >
                          <ContentCopyIcon
                            fontSize="inherit"
                            className="me-1"
                          />{" "}
                          Copy
                        </Button>
                      </CopyToClipboard>
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
          </Fragment>
        </div>
      </Stack>
    </div>
  );
};

export default SocialMedia;
