import { Fragment, Suspense, lazy, useEffect, useRef, useState } from "react";
import { AppHistoryRequest } from "../../../redux/slices/AppHistorySlice";
import ChatSkelton from "../../../components/common/ChatSkelton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Link, useNavigate, useParams } from "react-router-dom";
import Toaster from "../../../components/common/Toaster";
import ModeIcon from "@mui/icons-material/Mode";
import { Alert, Grid, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import ico from "../../../assets/images/favicon.png";
import { toast } from "react-toastify";
import axios from "axios";
import { Button } from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import DownloadIcon from "@mui/icons-material/Download";

import {
  BASEURL,
  headers,
  checktoken,
  markdownToText,
  convertHTMLToPlainText,
} from "../../../utils/helper";

import DOMPurify from "dompurify";
import EditIcon from "@mui/icons-material/Edit";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import { marked } from "marked";
const MarkdownViewer = lazy(() =>
  import("../../../components/chat/MarkdownViewer")
);

const History = () => {
  const chatBlock = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type, id } = useParams();
  const [height, setHeight] = useState(0);
  const [showTextArea, setShowTextArea] = useState(false);
  const [tempId, setTempId] = useState("");
  const [textAreaValue, setTextAreaValue] = useState("");
  const [updateEmailLoading, setUpdateEmailLoading] = useState(false);
  console.log("type: ", type);
  // const navigate = useNavigate();
  const user = useSelector((state) => state.AuthSlice);
  const { data: prompts, loading } = useSelector(
    (state) => state.AppHistorySlice
  );
  // console.log("prompts.../", prompts);

  const handleSave = (promptId, type, airesponse) => {
    console.log("Saving");
    setUpdateEmailLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/email/prompt/${promptId}`,
        { id: promptId, type: type, airesponse: airesponse },
        {
          headers: headers(),
        }
      )
      .then((response) => {
        console.log("Response:", response.data.data);
        if (type && id) {
          dispatch(AppHistoryRequest({ id, type }));
        }
        setUpdateEmailLoading(false);
        // prompts = response?.data?.data
        setShowTextArea(false);
        // Handle response data as needed
      });
    // setTextAreaValue(e.target.value);
  };
  const handleCopy = (content) => {
    content
      ? toast.success("Content copied to clipboard!")
      : toast.error("Content not found !");
  };
  const editQuestion = (id, type, airesponse) => {
    const payload = { id: id, type: type };
    console.log(payload);
    setTempId(id);
    setTextAreaValue(airesponse);
    setShowTextArea((prevState) => !prevState);
    // axios.post(`${BASEURL}/prompt/${id}`, {
    // 	headers: headers(),
    // })
  };
  const routes = {
    social: "/user/chat/social-media",
    article: "/user/chat/articles",
    email: "/user/chat/articles",
    // Add more types and their corresponding routes here
  };
  const pathname = routes[type];
  const handleChange = (e) => {
    setTextAreaValue(e.target.value);
  };
  useEffect(() => {
    if (type && id) {
      dispatch(AppHistoryRequest({ id, type }));
    }
    setHeight(document.getElementById("chat-layout").offsetHeight);
  }, [navigate]);

  const handleDownload = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName || "download.mp3");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  return (
    <div
      className="flex flex-col"
      style={{ height: `calc(100vh - ${height}px)` }}
    >
      <Toaster />
      {loading && <ChatSkelton />}
      <div
        id="chat-body"
        className="grow chat-block overflow-y-auto h-full p-[20px]"
        ref={chatBlock}
      >
        {prompts?.length > 0 && (
          <Grid container className={`hide-scroll-bar chat-section mt-3`}>
            {prompts.map((item, index) => (
              <Fragment key={index}>
                {item.role === "user" && item.prompt !== "" && (
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start ${item.promptrole} w-100`}
                  >
                    <div className="d-flex ms-auto flex-grow-1 align-items-start">
                      <div className="d-block">
                        <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2">
                          {user?.firstname?.split("")[0]}
                          {user?.lastname?.split("")[0]}
                        </span>
                      </div>
                      <div
                        className="leading-loose w-100"
                        style={{
                          whiteSpace: "break-spaces",
                          wordBreak: "break-word",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {item.promptType === "image" ? (
                            <>
                              <div>
                                <img
                                  className="h-96 object-contain"
                                  src={item.prompt}
                                  alt=""
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="question"
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(item.prompt),
                                }}
                              />
                            </>
                          )}
                          {/* <ModeIcon
                            onClick={() =>
                              editQuestion(item._id, item.type, item.airesponse)
                            }
                          >
                            <ContentCopyIcon fontSize="inherit" />
                          </ModeIcon> */}
                        </div>
                      </div>
                      {(type === "social" ||
                        type === "article" ||
                        type === "email") && (
                        <div
                          onClick={() => {
                            if (type === "email")
                              editQuestion(
                                item._id,
                                item.type,
                                item.airesponse
                              );
                            else {
                              navigate(pathname, {
                                state: { historyId: item._id },
                              });
                            }
                          }}
                          className="cursor-pointer"
                        >
                          <EditIcon />
                        </div>
                      )}
                    </div>
                  </Grid>
                )}

                {item.airole === "assistant" && (
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start w-100 answer`}
                  >
                    <div className="d-block">
                      <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2 p-1">
                        <img className="w-100 h-100" src={ico} alt="ico" />
                      </span>
                    </div>
                    <div className="d-block flex-grow-1 answer-div">
                      <div
                        className={`leading-loose ${
                          item.type === "image" ? "d-block" : ""
                        }`}
                        style={{ fontWeight: 500 }}
                      >
                        {item.type === "textToVideo" ||
                        item.type === "textToVoice" ? (
                          <>
                            <div className="d-flex flex-grow-1 align-items-end justify-content-between">
                              <div
                                className="text-center mx-auto position-relative dalle-image"
                                style={{ maxWidth: "400px" }}
                              >
                                <div className="mt-10  border border-gray-200">
                                  {/* <Col sm={12} md={6}> */}
                                  <div className="w-full mt-8 mb-4">
                                    <video className="w-full" controls>
                                      <source
                                        src={item.airesponse}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  </div>
                                  {/* </Col> */}
                                </div>
                                <div className="position-absolute top-0 start-0 end-0 p-2 text-start ">
                                  <a
                                    href={
                                      item.type === "textToVideo" &&
                                      item.airesponse
                                    }
                                    onClick={() => {
                                      item.type === "textToVoice" &&
                                        handleDownload(
                                          item.airesponse,
                                          // videoName + "-" +
                                          Date.now() + ".mp3"
                                        );
                                    }}
                                    target="_blank"
                                  >
                                    <IconButton
                                    // onClick={() => {
                                    //   dispatch(ImageDownload({ id: item?._id }));
                                    // }}
                                    >
                                      <DownloadIcon />
                                    </IconButton>
                                  </a>
                                </div>
                              </div>
                              {/* {(Index !== index) &&
                                                <CustomTooltip title="Edit Image" arrow placement='top' content={<IconButton onClick={() => { setImgUrl(item.airesponse); RenameAction(item.prompt, index) }}>
                                                    <EditOutlinedIcon />
                                                </IconButton>} />
                                            } */}
                            </div>
                          </>
                        ) : (
                          <>
                            {item.type === "image" ? (
                              <div style={{ maxWidth: "400px" }}>
                                <img
                                  src={item.airesponse}
                                  alt="image"
                                  className="rounded mx-auto w-100"
                                />
                              </div>
                            ) : (
                              <Suspense fallback={<div>Loading...</div>}>
                                {showTextArea && tempId == item._id ? (
                                  <>
                                    <textarea
                                      name=""
                                      id=""
                                      style={{ width: "100%", height: "200px" }}
                                      onChange={handleChange}
                                      value={textAreaValue}
                                    ></textarea>
                                    <div className="flex justify-end">
                                      <button
                                        className="px-4 rounded bg-primary-light text-white"
                                        onClick={() =>
                                          handleSave(
                                            item._id,
                                            item.type,
                                            textAreaValue
                                          )
                                        }
                                      >
                                        Save{" "}
                                        {updateEmailLoading === true && (
                                          <ButtonSpinner />
                                        )}
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {type === "translation" ||
                                    type === "rephrase" ? (
                                      <>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              item.airesponse
                                            ),
                                          }}
                                        ></div>
                                      </>
                                    ) : (
                                      <>
                                        <MarkdownViewer
                                          className="answer-content"
                                          content={DOMPurify.sanitize(
                                            item.airesponse
                                          )?.replaceAll(/【.*?】/g, "")}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </Suspense>
                            )}
                          </>
                        )}
                      </div>
                      {!showTextArea && item?._id && item?.type !== "image" && (
                        <div className="d-flex align-items-center justify-content-end mt-1">
                          {type === "article" ? (
                            <div className="flex gap-4">
                              <CopyToClipboard
                                text={markdownToText(item.airesponse)}
                              >
                                <Button
                                  className="d-flex align-items-center bg-primary-light hover:bg-primary-main text-white"
                                  onClick={() => handleCopy(item.airesponse)}
                                >
                                  <ContentCopyIcon
                                    fontSize="inherit"
                                    className="me-1"
                                  />
                                  Copy as plain text
                                </Button>
                              </CopyToClipboard>
                              <CopyToClipboard text={marked(item.airesponse)}>
                                <Button
                                  className="d-flex align-items-center bg-primary-light hover:bg-primary-main text-white"
                                  onClick={() => handleCopy(item.airesponse)}
                                >
                                  <ContentCopyIcon
                                    fontSize="inherit"
                                    className="me-1"
                                  />
                                  Copy as html
                                </Button>
                              </CopyToClipboard>
                            </div>
                          ) : (
                            <>
                              <CopyToClipboard
                                text={
                                  item.type === "image"
                                    ? item.airesponse
                                    : type === "translation"
                                    ? convertHTMLToPlainText(item.airesponse)
                                    : type === "rephrase"
                                    ? convertHTMLToPlainText(item.airesponse)
                                    : item?.airesponse?.replaceAll(
                                        /【.*?】/g,
                                        ""
                                      )
                                }
                              >
                                <IconButton
                                  onClick={() => handleCopy(item.airesponse)}
                                >
                                  <ContentCopyIcon fontSize="inherit" />
                                </IconButton>
                              </CopyToClipboard>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </Grid>
                )}
              </Fragment>
            ))}
            {!prompts?.length && (
              <div className="col-12 p-3">
                <Alert severity="info" variant="filled">
                  Content not available is this project.
                </Alert>
              </div>
            )}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default History;
