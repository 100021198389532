import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const Welcome = () => {
  const location = useLocation();
  const { user } = location.state || { user: null }; // Destructure historyId from state
  const nav = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!user) {
      // if (!token) nav("/login");
      // else nav("/user/chat/outside");
    }
  }, []);
  return (
    <>
      <div className="h-[100%] flex flex-col gap-3 justify-center items-center ">
        <div className="w-1/2  flex flex-col gap-3 justify-center items-center mx-auto">
          <p className="text-2xl font-medium text-center">
            Thank you for signing up with Top Executives AI Assistant Click
            here, to go to homepage and start your free trial
          </p>
          <div className="w-1/3 mx-auto">
            <Button
              onClick={() => {
                nav("/user/chat/outside");
              }}
              className="primary-btn-1 text-uppercase w-100"
            >
              Go To HOME
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
