import {
  Button as MuiBtn,
  Menu,
  MenuItem,
  Avatar,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTranslation } from "react-i18next";
import Select from "react-dropdown-select";
import Button from "@mui/material/Button";
import { useTour } from "@reactour/tour";
import { Cookies } from "react-cookie";
import i18next from "i18next";
import axios from "axios";
import { headers, checktoken, BASEURL } from "../../../utils/helper";
const Header = () => {
  const { _id, image, currentplan } = useSelector((state) => state.AuthSlice);
  const [selectedLang, setSelectedLang] = useState([]);
  const [langmodal, setLangmodal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [project, setProject] = useState();
  const [modal, setModal] = useState(false);
  const [text, setText] = useState("");
  const { pathname } = useLocation();
  const { type, id } = useParams();
  const { setIsOpen } = useTour();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const open = Boolean(anchor);
  const languages = [
    {
      dir: "rtl",
      code: "fa",
      name: "فارسی",
      country_code: "IR",
    },
    {
      dir: "ltr",
      code: "en",
      name: "English",
      country_code: "EN",
    },
  ];

  const logOut = (e) => {
    setSpinner(true);
    e.preventDefault();
    axios
      .post(
        `${BASEURL}/logout`,
        { id: _id },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res.data?.success === true) {
          cookies.remove("auth", { path: "/" });
          cookies.remove("refreshToken", { path: "/" });
          navigate(res.data.redirect);
        }
        setSpinner(false);
      })
      .catch((err) => {
        checktoken(err);
        setSpinner(false);
      });
  };

  const StartUserTour = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setLangmodal(false);
  };

  const handleModalClose = () => {
    setModal(false);
  };

  const getProject = (id, type) => {
    axios
      .get(`${BASEURL}/user/project/info/${id}/${type}`, {
        headers: headers(),
      })
      .then((res) => {
        if (res.data.success === true) {
          setProject(res.data.data.title);
        }
      })
      .catch((err) => {
        checktoken(err);
      });
  };

  useEffect(() => {
    const currentLangCode = cookies.get("i18next") || "en";
    const currentLanguage = languages.find((l) => l.code === currentLangCode);
    setSelectedLang([...selectedLang, currentLanguage]);
    setAnchor(null);
    if (type && id) {
      getProject(id, type);
    }
  }, [navigate]);

  return (
    <div>
      <div
        id="chat-header"
        className="d-flex justify-content-between p-2 px-sm-3 shadow-sm align-items-center flex-wrap position-relative"
        style={{ zIndex: 9 }}
      >
        <div className="d-flex d-md-block justify-content-between flex-grow-1 mb-3 mb-sm-0">
          <h3>Business insight does not need subscription</h3>
          {window.innerWidth < 600 && (
            <Fragment>
              <MuiBtn
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                className="p-0"
                aria-expanded={open ? "true" : undefined}
                style={{ minWidth: "40px" }}
                onClick={(e) => {
                  setAnchor(e.currentTarget);
                }}
              >
                {image === null ? (
                  <AccountCircleIcon className="text-white fs-2" />
                ) : (
                  <Avatar src={image} sx={{ height: 30, width: 30 }} />
                )}
              </MuiBtn>
            </Fragment>
          )}
        </div>
        <Stack direction="row" spacing={3} className="justify-end">
          <div className="d-flex flex-wrap">
            <Button
              className="bg-secondary-main text-white hover:!text-secondary-main ff-lato ms-1"
              // startIcon={<CreditCardIcon />}
              LinkComponent={Link}
              to="/login"
            >
              Login
            </Button>

            <Button
              className="bg-secondary-main text-white hover:!text-secondary-main ff-lato ms-1"
              // startIcon={<CreditCardIcon />}
              LinkComponent={Link}
              to="/about"
            >
              About Us
            </Button>
            <Button
              className="bg-secondary-main text-white hover:!text-secondary-main ff-lato ms-1"
              // startIcon={<CreditCardIcon />}
              LinkComponent={Link}
              to="/contact"
            >
              Contact Us
              {/* {currentplan ? t("upgrade_text") : t("membership")} */}
            </Button>
            <Button
              className="bg-secondary-main text-white hover:!text-secondary-main ff-lato ms-1"
              // startIcon={<CreditCardIcon />}
              LinkComponent={Link}
              to="/faqs"
            >
              FAQs
              {/* {currentplan ? t("upgrade_text") : t("membership")} */}
            </Button>
            <Button
              className="bg-secondary-main text-white hover:!text-secondary-main ff-lato ms-1 mb-1 mb-sm-0"
              // startIcon={<AttachMoneyIcon />}

              LinkComponent={Link}
              to="/signup"
            >
              {t("get_credit")}
            </Button>
            <Button
              className="bg-secondary-main text-white hover:!text-secondary-main ff-lato ms-1 mb-1 mb-sm-0"
              startIcon={<CreditCardIcon />}
              LinkComponent={Link}
              to="/pricing"
            >
              Start Free trial
            </Button>

            {window.innerWidth > 600 && (
              <Fragment>
                <MuiBtn
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  className="p-0"
                  aria-expanded={open ? "true" : undefined}
                  style={{ minWidth: "40px" }}
                  onClick={(e) => {
                    setAnchor(e.currentTarget);
                  }}
                >
                  {image === null ? (
                    <AccountCircleIcon className="text-white fs-2" />
                  ) : (
                    <Avatar src={image} sx={{ height: 30, width: 30 }} />
                  )}
                </MuiBtn>
              </Fragment>
            )}
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default Header;
