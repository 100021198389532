import React, { useState } from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import { Form, Stack, Card } from "react-bootstrap";
import CustomTooltip from "../../../components/common/CustomTooltip";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { BASEURL, headers } from "../../../utils/helper";
import axios from "axios";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import { useNavigate } from "react-router-dom";
import Toaster from "../../../components/common/Toaster";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleContactUs = async (e) => {
    e.preventDefault();
    //  description topic videoScriptLength
    if (!firstname || !lastname || !email || !phone || !message) {
      toast.success("Please Enter All Fields!");
      return false;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${BASEURL}/contact-us`,
        { firstname, lastname, email, phone, message },
        {
          headers: headers(),
        }
      );
      toast.success("Email Sent to Admin!");

      console.log("handleContactUs response.../", response?.data);
      // setGenScriptBool(false);

      setTimeout(() => {
        navigate("/");
      }, 3000);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Container
        sx={{
          bgcolor: "white",
          py: { xs: 2, sm: 4 },
          maxWidth: "95%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid container>
          <Toaster />

          <div className="my-8">
            <Grid item xs={12}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: {
                    xs: "20px",
                    sm: "40px",
                  },
                  fontWeight: "bold",
                  mb: 2,
                  color: "#308db9",
                  textAlign: "center",
                }}
              >
                Contact Us
              </Typography>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      backgroundColor: "#6C0345",
                      padding: "20px",
                      borderRadius: 4,
                      textAlign: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={"../logo.png"}
                      alt={"logo"}
                      sx={{
                        width: { xs: "80%", md: "100%" },
                        borderRadius: 6,
                        padding: "10px",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#fff",
                        marginTop: "20px",
                      }}
                    >
                      TopExecutives is here to help small and medium businesses
                      thrive and grow.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Form
                    onSubmit={(e) => {
                      handleContactUs(e);

                      // user?.currentplan ? handleSubmit(e) : e.preventDefault();
                    }}
                  >
                    <Form.Group className="mb-3 input-field">
                      <CustomTooltip
                        title="First Name:"
                        arrow
                        placement="left"
                        content={
                          <Form.Control
                            type="text"
                            placeholder="First Name:"
                            className="custom-field custom-textarea"
                            value={firstname}
                            //   rows={rows}
                            onChange={(e) => {
                              setFirstname(e.target.value);
                            }}
                            onFocus={() => {
                              // setTitle("");
                            }}
                          />
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 input-field">
                      <CustomTooltip
                        title="Last Name"
                        placement="left"
                        arrow
                        content={
                          <Form.Control
                            type="text"
                            placeholder="Last Name:"
                            className="custom-field"
                            value={lastname}
                            onChange={(e) => {
                              setLastname(e.target.value);
                            }}
                          />
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-3 input-field">
                      <CustomTooltip
                        title="Business Phone"
                        arrow
                        placement="left"
                        content={
                          <Form.Control
                            type="text"
                            placeholder="Business Phone"
                            className="custom-field"
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                          />
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-3 input-field">
                      <CustomTooltip
                        title="Email"
                        arrow
                        placement="left"
                        content={
                          <Form.Control
                            type="text"
                            placeholder="Email"
                            className="custom-field"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-3 input-field">
                      <CustomTooltip
                        title="How Can We Help?"
                        arrow
                        placement="left"
                        content={
                          <Form.Control
                            as="textarea"
                            placeholder="How Can We Help?"
                            className="custom-field custom-textarea"
                            value={message}
                            rows={7}
                            onChange={(e) => {
                              setMessage(e.target.value);
                            }}
                            onFocus={() => {
                              // setTitle("");
                            }}
                          />
                        }
                      />
                    </Form.Group>
                    {/* <div className="flex justify-center"> */}
                    <Button
                      type="submit"
                      disabled={loading}
                      className="bg-primary-light hover:bg-primary-main text-white"
                    >
                      Submit
                      {loading === true && <ButtonSpinner />}
                    </Button>
                    {/* </div> */}
                  </Form>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Container>
    </>
  );
};

export default Contact;
