import { Grid, Stack } from "@mui/material";
import React, { Fragment, Suspense, useRef, useState } from "react";
import MarkdownViewer from "../../../components/chat/MarkdownViewer";
import DOMPurify from "dompurify";
import ico from "../../../assets/images/favicon.png";

const LandingChat = () => {
  const [chat, setChat] = useState([]);
  const chatBlock = useRef();

  const scrollChat = () => {
    setTimeout(() => {
      const objDiv = chatBlock.current;
      if (objDiv) {
        objDiv.scrollTo({
          top: objDiv.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    });
  };

  const handleWhyPrompt = () => {
    const obj = [
      {
        prompt: "Why Topexecutives is more than chatgpt4?",
        role: "user",
        airole: "assistant",
        airesponse: "", // Start with an empty response
        type: "chat",
      },
    ];

    setChat(obj); // Set initial chat state

    const fullResponse = `# Platform Overview

The platform you're using offers robust functionalities beyond the standard ChatGPT, powered by the latest GPT-4.0 technology. It includes various features to support business operations and insights.

## Advantages over ChatGPT-4

### 1. **Multilingual Reading**
   - The business insight module includes podcasts that can be read in **80 languages**, ensuring accessibility for a global audience while providing consistent content.

### 2. **Extended Tokens for Content Creation**
   - The article writer and translator modules can handle **three times more tokens per request** compared to ChatGPT, enabling more detailed and comprehensive answers.

### 3. **Module Diversity**
   - The platform integrates various modules such as:
     - **Article Writer**
     - **Doc Reader**
     - **Social Media Manager**
     - **Translator**
   - These allow businesses to streamline multiple operations within one system.

### 4. **Advanced Features**
   - The platform includes next-gen capabilities such as:
     - **Text to Video**
     - **Text to Voice**
   - These enhance how businesses present and consume information.

### 5. **Organized Management**
   - The **Project Pool** feature aids in maintaining organized and efficient workflow management, crucial for team collaborations and project tracking.

### 6. **Business Insights**
   - Access to a wealth of business insights and resources, including:
     - **Texts**
     - **Podcasts on various topics**
   - This helps businesses stay informed and make data-driven decisions.
`;

    let currentIndex = 0;

    const streamResponse = setInterval(() => {
      if (currentIndex < fullResponse.length) {
        obj[0].airesponse += fullResponse[currentIndex]; // Add one character at a time
        obj[0].airesponse += fullResponse[currentIndex + 1]; // Add one character at a time
        setChat([...obj]); // Update chat state
        currentIndex += 2;
        scrollChat();
      } else {
        clearInterval(streamResponse); // Stop the interval once the full response is added
      }
    }, 1); // Adjust speed (milliseconds per character)
  };

  return (
    <>
      <div
        id="chat-body"
        className="grow flex items-center w-full h-full p-[20px]"
        ref={chatBlock}
      >
        {chat.length > 0 ? (
          <Grid
            container
            className={`hide-scroll-bar chat-section ${
              chat.length > 0 ? "flex justify-end flex-col" : ""
            }`}
          >
            {chat.map((item, index) => (
              <Fragment key={index}>
                {item.role === "user" && item.prompt !== "" && (
                  <div>
                    <Grid
                      item
                      xs={12}
                      md={10}
                      className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start question w-100`}
                    >
                      <div className="d-flex ms-auto flex-grow-1 align-items-start">
                        <div className="d-block">
                          <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2">
                            {/* {user?.firstname?.charAt(0)}
                        {user?.lastname?.charAt(0)} */}
                          </span>
                        </div>
                        <div
                          className="leading-loose w-100"
                          style={{
                            whiteSpace: "break-spaces",
                            wordBreak: "break-word",
                          }}
                        >
                          <div
                            className="question"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                item.prompt
                                  .replaceAll("\\n", "")
                                  .replaceAll("<br>", "")
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </div>
                )}
                {item.airole === "assistant" && (
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start w-100 answer`}
                  >
                    <div className="d-block">
                      <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2 p-1">
                        <img className="w-100 h-100" src={ico} alt="ico" />
                      </span>
                    </div>
                    <div
                      className="d-block flex-grow-1 answer-div"
                      style={{ width: "calc(100% - 43px)" }}
                    >
                      <div
                        className="leading-loose"
                        style={{ fontWeight: 500 }}
                      >
                        <Suspense fallback={<div>Loading...</div>}>
                          <MarkdownViewer
                            className="answer-content"
                            content={DOMPurify.sanitize(item.airesponse)
                              .replaceAll("GPT-3", "GPT-4")
                              .replaceAll("gpt-3", "GPT-4")
                              .replaceAll(/【.*?】/g, "")}
                          />
                        </Suspense>
                      </div>
                    </div>
                  </Grid>
                )}
              </Fragment>
            ))}
          </Grid>
        ) : (
          <>
            <Stack className="h-100 w-full">
              <div className="text-center col">
                <div className="h-100 d-grid" style={{ placeItems: "center" }}>
                  <div>
                    <h1>ChatGPT 4o</h1>
                    <h2
                      onClick={() => {
                        handleWhyPrompt();
                      }}
                      className="mt-3 cursor-pointer underline"
                    >
                      Why Topexecutives is more than chatgpt4
                    </h2>
                  </div>
                </div>
              </div>
            </Stack>
          </>
        )}
      </div>
    </>
  );
};

export default LandingChat;
