import { BASEURL, checktoken, headers, languages } from "../../../utils/helper";
import {
  DeletePromptRequest,
  SavePromptRequest,
} from "../../../redux/slices/UserPromptSlice";
import { BizReaderRequest } from "../../../redux/slices/BizReaderSlice";
import { Fragment, useEffect, useMemo, useState } from "react";
import TextLoader from "../../../components/common/TextLoader";
import TranslateIcon from "@mui/icons-material/Translate";
import { useNavigate, useParams } from "react-router-dom";
import Toaster from "../../../components/common/Toaster";
import { Button, Skeleton, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import Select from "react-dropdown-select";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";

const LandingPrompt = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [chat, setChat] = useState([]);
  const [title, setTitle] = useState("");
  const [video, setVideo] = useState("");
  const [content, setContent] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [isTranslate, setIsTranslate] = useState(false);
  const {
    data: prompt,
    loading,
    translated,
  } = useSelector((state) => state.BizReaderSlice);
  const [controller, setController] = useState(new AbortController());

  const handleStopStream = (e) => {
    id && dispatch(DeletePromptRequest(id));
    controller.abort();
    setController(new AbortController());
    id && dispatch(BizReaderRequest(id));
  };

  const TranslateText = async (language) => {
    console.log("language.../", language);
    setSpinner(true);
    try {
      const message = JSON.stringify({ language, content });
      const response = await fetch(`${BASEURL}/user/translate/text`, {
        method: "POST",
        responseType: "stream",
        signal: controller.signal,
        headers: headers(),
        body: message,
      });
      const readData = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      // dispatch(DeletePromptRequest(id));
      setIsTranslate(true);
      setSpinner(false);
      let aiRes = "";
      while (true) {
        const { done, value } = await readData.read();
        if (done) {
          break;
        }
        aiRes += value;
        setChat([...chat, { airesponse: aiRes }]);
      }
      // if (language !== "English") {
      //   dispatch(
      //     SavePromptRequest({
      //       prompt: id,
      //       text: aiRes,
      //     })
      //   );
      // }
    } catch (err) {
      // checktoken(err);
      setSpinner(false);
      // if (err?.response?.data?.error !== undefined) {
      //   toast.error(err.response.data.error);
      // }
    }
  };

  useMemo(() => {
    if (prompt && Object.keys(prompt).length > 0) {
      setTitle(prompt?.title);
      setVideo(prompt?.video);
      setContent(prompt?.description);
    }
    return true;
  }, [prompt]);

  useEffect(() => {
    if (id) {
      setIsTranslate(false);
      if (prompt && Object.keys(prompt).length > 0) {
        setTitle(prompt?.title);
        setVideo(prompt?.video);
        if (!translated) setContent(prompt?.description);
      }
      if (translated && translated?.text) {
        setContent(translated?.text);
      }
    }
  }, [id, prompt, translated]);

  useEffect(() => {
    const fetch = async () => {
      const bizData = await dispatch(BizReaderRequest(id));
    };
    id && fetch();
    setChat([]);
    setIsTranslate(false);
    // id && dispatch(BizReaderRequest(id));
  }, [id, navigate]);

  return (
    <div id="featured-schollar" className="p-3" style={{ height: "100vh" }}>
      <Toaster />
      {spinner === true && <TextLoader />}
      {loading === true && (
        <Row>
          <Col md={12} className="m-auto mb-3">
            <Stack spacing={1}>
              <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
              <Skeleton variant="rounded" height={200} />
            </Stack>
          </Col>
        </Row>
      )}
      {title !== "" && content !== "" && loading === false && (
        <Row>
          <Col md={12} className="m-auto mb-3">
            <Card className="shadow-sm border-0">
              <Card.Title className="p-3 m-0 border-bottom d-flex align-ites-center justify-content-between flex-wrap">
                <h4 className="m-0 mb-2 mb-sm-0">{title}</h4>
                {isTranslate === true ||
                (isTranslate === false && translated?.text) ? (
                  <Button
                    className="btn-thm-1"
                    onClick={() => {
                      handleStopStream();
                      setIsTranslate(false);
                      setChat([]);
                    }}
                  >
                    <span>
                      <TranslateIcon />
                    </span>
                    <span>Reset</span>
                  </Button>
                ) : (
                  <div className="btn-thm-1 d-flex align-items-center">
                    <span>
                      <TranslateIcon />
                    </span>
                    <Select
                      className="shadow-sm multiselect-dropdown"
                      style={{ width: "200px" }}
                      options={languages}
                      searchable={true}
                      placeholder="Choose a language"
                      labelField="value"
                      valueField="value"
                      onChange={(value) => {
                        TranslateText(value.map(({ value }) => value)[0]);
                      }}
                    />
                  </div>
                )}
              </Card.Title>
              <Card.Body>
                {video !== "" && video !== null && prompt?.type === "video" && (
                  <div
                    className="d-block border rounded p-2 mb-3"
                    style={{ height: "500px" }}
                  >
                    <video
                      className="w-100 h-100 object-fit-contain bg-[#363c3e] rounded"
                      loop
                      controls
                      preload="auto"
                    >
                      <source src={video} type="video/mp4" />
                    </video>
                  </div>
                )}
                {video !== "" && video !== null && prompt?.type === "audio" && (
                  <div className="d-block border rounded p-2 mb-3 text-center">
                    <audio
                      className=" bg-[#363c3e] rounded m-auto"
                      loop
                      controls
                      preload="auto"
                    >
                      <source src={video} type="audio/mp3" />
                    </audio>
                  </div>
                )}
                {isTranslate && (
                  <Fragment>
                    {chat.map((item, index) => (
                      <div
                        key={index}
                        className="question"
                        dangerouslySetInnerHTML={{ __html: item.airesponse }}
                      />
                    ))}
                  </Fragment>
                )}
                {isTranslate === false && !translated?.text && (
                  <div
                    className="question"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content),
                    }}
                  />
                )}

                {isTranslate === false && translated?.text && (
                  <div
                    className="question"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(translated?.text),
                    }}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default LandingPrompt;
