import React from "react";
import {
  Container,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const faqs = [
  {
    question: "What is the Article Writer on TopExecutives.ai?",
    answer:
      "The Article Writer on TopExecutives.ai is an advanced module built upon ChatGPT, allowing for the creation of detailed and lengthy articles. Unlike the standard ChatGPT, this module can handle much larger text inputs and deliver comprehensive content tailored to professional and business needs.",
  },
  {
    question:
      "How does the Translator on TopExecutives.ai differ from other translation tools?",
    answer:
      "Our Translator is an enhanced version of ChatGPT, designed to process and translate longer texts with higher accuracy. It supports over 80 languages, making it ideal for business documents, educational content, and more, ensuring that the contextual integrity of your text is maintained.",
  },
  {
    question: "What is ExecutiveGPT on TopExecutives.ai?",
    answer:
      "ExecutiveGPT is a specialized ChatGPT-4.0 module, optimized for executive tasks. It helps in managing complex decisions, data analysis, and high-level communication, providing insights that are essential for business leaders.",
  },
  {
    question: "How can I use the Text-to-Video module on TopExecutives.ai?",
    answer:
      "The Text-to-Video module converts your written ideas into professional-grade videos that can be used for social media, marketing, education, and more. This tool is perfect for creating engaging content quickly and efficiently.",
  },
  {
    question: "What does the Text-to-Voice module offer?",
    answer:
      "The Text-to-Voice module transforms your text into natural-sounding speech. It’s ideal for creating audio versions of your documents, voiceovers for videos, or even podcasts, making it versatile for various business and creative needs.",
  },
  {
    question: "Can I save and retrieve my work on TopExecutives.ai?",
    answer:
      "Yes, you can save all your projects within TopExecutives.ai under specific module categories. You can easily access, edit, and continue your work at any time, ensuring seamless productivity.",
  },
  {
    question: "How do I upgrade my plan on TopExecutives.ai?",
    answer:
      "Upgrading your plan is simple. Just log in to your account and click on the 'Upgrade' button. Choose the plan that best suits your needs, and the upgrade will take effect immediately.",
  },
  {
    question: "What should I do if I encounter technical issues?",
    answer:
      "If you experience any technical issues, you can click on the 'Support' button in your dashboard. From there, you can submit your concerns or questions, and our support team will assist you promptly.",
  },
  {
    question: "What are the pricing options for TopExecutives.ai?",
    answer:
      "We offer several pricing plans: Execs Basic: $31.00/month with 300 requests per month and access to essential modules like ChatGPT4-o, Translator, and Rephraser. Enterprise Plan: $46.00/month which includes all 12 modules and offers 480 minutes video making over 6 months, creating an enterprise-level environment. Text-To-Video: $20.00/month providing 80 minutes per month to convert your ideas into videos.",
  },
  {
    question: "How does TopExecutives.ai compare with standard ChatGPT?",
    answer:
      "TopExecutives.ai offers an enhanced and specialized version of ChatGPT, tailored specifically for business executives. It includes modules like ExecutiveGPT for high-level decision-making and industry-specific tools that go beyond the general capabilities of ChatGPT.",
  },
  {
    question: "How can I use the Social Media module on TopExecutives.ai?",
    answer:
      "Our Social Media module helps you generate optimized captions and hashtags for your posts, increasing engagement on platforms like Instagram and Facebook. It’s an essential tool for managing your brand’s online presence.",
  },
  {
    question: "What features does the HR Upload module offer?",
    answer:
      "The HR Upload module allows you to upload 50 resumes each time, letting our platform analyze and select the most suitable candidates efficiently. It provides insights and recommendations, speeding up your hiring process and ensuring you find the best candidates.",
  },
  {
    question: "How can I create a new project on TopExecutives.ai?",
    answer:
      "To create a new project, log in to your account, choose the module you want to use, click on new project and name your project, and start working. Once you are done click on save and your projects are saved automatically, so you can come back to them anytime.",
  },
  {
    question:
      "What is the difference between the Execs Basic and Enterprise Plan?",
    answer:
      "The Execs Basic plan offers essential tools with 300 requests per month, ideal for small to medium-sized tasks. The Enterprise Plan includes all 12 modules with unlimited usage allowance, providing an enterprise-level environment suitable for larger businesses.",
  },
  {
    question: "Is there a free trial available for TopExecutives.ai?",
    answer:
      "Yes, we offer a 5-day free trial for all plans. This allows you to explore our features and see how TopExecutives.ai can benefit your business before committing to a subscription.",
  },
];

const FAQ = () => {
  return (
    <Container sx={{ bgcolor: "#f8f9fa", py: { xs: 2, sm: 4 } }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          mb: 4,
          color: "#308db9",

          textAlign: "center",
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Grid container spacing={4}>
        {faqs.map((faq, index) => (
          <Grid item xs={12} key={index}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  variant="h6"
                  sx={{ color: "#495057", fontWeight: "bold" }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" sx={{ color: "#495057" }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FAQ;
